



























































import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/admin/components/PageHeader.vue";
import __ from "@/shared/helpers/__";
import { CompanyRoutes } from "@/shared/router/company";
import { getNodeApiUrlByVersion } from "@/config";
import baseHttp from "@/shared/http";
import FormField from "@/shared/components/form/FormField.vue";
import FormBase from "@/shared/classes/form/form-base";
import Form from "@/shared/components/form/Form.vue";
import CourseTestFinished from "@/company/components/courses/CourseTestFinished.vue";

@Component({
  components: { CourseTestFinished, PageHeader, FormField, Form },
  methods: { __ }
})
export default class CourseTest extends Vue {
  http = baseHttp

  course: any = null
  testAttemptUuid = '';
  form = new FormBase()
    .setTranslatable(true)
    .setSubmit(false);
  activeQuestionIndex = -1
  answers: Record<string, any> = {}

  testScore = 0
  testPassed = false
  testFinished = false
  wrongTests = []


  created() {
    this.http.get(`${getNodeApiUrlByVersion()}/course-assignments/${this.$route.params.uuid}`)
      .then(resp => {
        this.course = resp.data?.course
        this.http.post(`${getNodeApiUrlByVersion()}/course-assignments/test-attempt-started/${this.$route.params.uuid}`, {
          testStartedAt: new Date()
        })
          .then(response => this.testAttemptUuid = response.data?.uuid)
          .catch(console.warn)
      })
      .catch(console.warn)
  }

  setAnswer(testUuid: string, answerIndex: number, value: boolean) {
    this.answers = { ...this.answers, [testUuid]: value ? answerIndex : null }
  }

  goBack() {
    this.$router.push({ name: CompanyRoutes.coursesIndex })
  }

  onPrev() {
    this.activeQuestionIndex--;
  }

  onNext() {
    this.activeQuestionIndex++;
  }

  onFinish() {
    this.http.post(`${getNodeApiUrlByVersion()}/course-assignments/test-attempt-finished/${this.$route.params.uuid}`, {
      testFinishedAt: new Date(),
      answers: this.answers,
      attemptUuid: this.testAttemptUuid
    })
      .then(response => {
        this.testScore = response.data?.testScore;
        this.testPassed = response.data?.testPassed;
        this.wrongTests = response.data?.wrongTests;
        this.testFinished = true;
      })
      .catch(console.warn)
  }

  get courseTestTitle() {
    return this.course?.testTitle?.[this.form.currentLanguage] || this.course?.name?.[this.form.currentLanguage];
  }

  get courseTestContent() {
    if (this.activeQuestionIndex === -1) return this.course?.testDescription?.[this.form.currentLanguage] || '';

    return this.course?.tests?.[this.activeQuestionIndex]?.text?.[this.form.currentLanguage] || '';
  }

  get courseTest() {
    if (this.activeQuestionIndex === -1) return null;

    return this.course?.tests?.[this.activeQuestionIndex];
  }

  get testsAmount() {
    return this.course?.tests?.length || 0;
  }

  get hasAllAnswers() {
    return Object.values(this.answers).filter(value => typeof value === 'number').length === this.testsAmount;
  }

}
